import { makeStyles } from '@mui/styles';

const SaveGridStyles = makeStyles(() => ({
    mainDilogBoxSection: {
        '& .MuiPaper-root': {
            borderRadius: '6px !important',
            width: '35%',
            maxWidth: '738px !important',
        },
    },
    dilogBoxSection: {
        borderBottom: '1px solid #CECECE',
        padding: '10px 32px !important',
        fontSize: '20px !important',
        fontWeight: '600 !important',
    },
    labelText: {
        marginBottom: '6px !important',
        color: '#353535',
    },
    text: {
        fontFamily: 'Inter !important',
        fontWeight: '500 !important',
        fontSize: '14px !important',
        color: '#353535',
        marginTop: '25px !important',
    },
    textHead: {
        fontFamily: 'Inter-Bold',
        fontWeight: '700',
        fontSize: '20px',
        color: '#e21c1c',
    },
    warning: {
        color: '#353535',
        fontSize: '15px !important',
        fontWeight: 500,
    },
    dialogBody: {
        backgroundColor: '#F9F9F9',
        padding: '0px !important',
        '& input': {
            padding: '6px',
            height: '22px',
            fontSize: '14px',
            borderRadius: '5px',
            color: '#605F5F',
            border: '1px solid #fdfdfd',
            backgroundColor: '#ffffff',
        },
    },
    sectionBody: {
        padding: '32px !important',
        '& .MuiFormControl-root': {
            margin: '0px !important',
            minWidth: '100%',
        },
    },
    dilogBoxSectionFooter: {
        padding: '10px 32px !important',
        borderTop: '1px solid #CECECE',
        '& button': {
            textTransform: 'capitalize',
            boxShadow: 'none',
            fontSize: '16px',
            padding: '7px 32px',
        },
    },
    outlineButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
        },
    },
    filledButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#fff',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: '#007DFF',
            boxShadow: `0px 2.85714px 4.7619px -1.90476px rgba(0, 0, 0, 0.2), 0px 1.90476px 2.85714px rgba(0, 0, 0, 0.14),
             0px 0.952381px 4.7619px rgba(0, 0, 0, 0.12)`,
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '&:hover': {
                backgroundColor: '#007DFF !important',
            },
        },
    },
    deleteButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#B00000',
            border: '1px solid #B00000',
            borderRadius: '4px',
            backgroundColor: '#fff',
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
        },
    },
}));

export default SaveGridStyles;

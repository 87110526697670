import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const GET_INDUSTRIES = (orgId: string) => gql`
query GetIndustries  {
  user_industries(org_id: ${addEscapeCharacters(orgId)}) {
    id
    code
    description
    project_count
    total_cost
    level
    areProjectsExcluded
    sub_industry
    industry_id
    is_industry
  }
}
`;

const GET_ALL_INDUSTRY = (skip: number, take: number, orgId: string, hideUnusedFlag?: boolean) => gql`
query {
  all_industries(skip: ${skip}, take: ${take}, org_id: ${addEscapeCharacters(orgId)}, hide_unused_flag: ${hideUnusedFlag}) {
    data {
      id
      code
      code1
      code2
      code3
      code4
      code5
      code6
      description
      project_count
      total_cost
      level
      attr_label
      unit
      first_parent
      second_parent
      third_parent
      forth_parent
      fifth_parent
      self_code_desc
      projects {
        id
        project_number
        estimate_type
        name
        region
        base_date
        owner
        country_name
        total_cost
        client_number
        unit
        capacity
        id
        base_date
        wbs1
        wbs2
        wbs3
        wbs4
        wbs5
        wbs6
        coa1
        coa2
        coa3
        coa4
        coa5
        coa6
        hide_values
        coa_sep
        name
        client_number
        region
        owner
        consultant
        consultant_number
        estimate_type
        estimate_accuracy
        contingency_wbs
        contingency_pct
        currency
        wbs_sep
        created_at
        updated_at
        project_number
        total_cost
        version_id
        version_data {
          id
        }
        version_code
        industry_id
        industry {
          description
        }
        subindustry_id
        subindustry {
          description
        }
        country {
          name
        }
        currency_id
        currency_data {
          id
          code
          exchange_rate
          symbol
        }
        is_active
        country_name
        latitude
        location
        longitude
        permission_id
        description
        curr_gwbs_id
        cost
      }
    }
    pageInfo {
      skip
      take
      totalcount
      hasNextPage
    }
  }
}`;

const DELETE_INDUSTRIES = (orgId: string, id: string) => gql`
mutation {
  deleteIndustries(input: { org_id: ${addEscapeCharacters(orgId)}, ids: ${id} }) {
    error
    error_code
    message
  }
}
`;

export {
    GET_INDUSTRIES, GET_ALL_INDUSTRY, DELETE_INDUSTRIES,
};

import React, { useContext, useRef, useState } from 'react';
import {
    Box, DialogContent, DialogTitle, Dialog, Button,
    DialogActions,
    DialogContentText,
} from '@mui/material';
import SaveNewReportModelStyle from './NewReportPopup.style';
import Report from '../../../../components/report';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import { ReportType } from '../../../../types/Reports';
import { UserContext } from '../../../../store/context/userContext';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
import reportQuery from '../../../../queries/report';
import Loader from '../../../../components/Loader';
import { DocumentChangedEventArgs, ReportInfo } from '@grapecity/activereports/reportdesigner';
import { Designer } from '@grapecity/activereports-react';
import getQueryDetailsByDataset from '../../../../components/report/helper';
import { ProjectContext } from '../../../../store/context/projectContext';
import { getAccessTokenFromLocalStorage, getBackendEndpoint } from '../../../../helper/StorageHelper';
import { ReportItem } from '@grapecity/activereports/lib/node_modules/@grapecity/ar-js-rdl';

interface CreateReportType {
    successMessage: string;
    id: string;
}

interface SaveNewReportModelProps {
    open: boolean,
    setOpen: (isOpen: boolean) => void,
    name: string,
    module: string,
    reportListUpdate: (isEditMode: boolean) => void,
    isEditMode: boolean,
    selectedReport: ReportType | null,
    updateEditedReport: (report: ReportType) => void,
}

function NewReportPopup({
    open, setOpen, name, module, reportListUpdate, isEditMode, selectedReport, updateEditedReport,
}: SaveNewReportModelProps) {
    const userCtx = useContext(UserContext);
    const orgID = userCtx?.user?.default_org_id;
    const classes = SaveNewReportModelStyle();
    const cont: OutletContext = useOutletContext();
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const curLocation = useLocation();
    const reportRef = React.useRef<Designer | null>(null);
    const runTimeReport = React.useRef<DocumentChangedEventArgs['definition'] | null>(null);
    const projectCtx = useContext(ProjectContext);
    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = displayCurr?.exchange_rate;
    const [viewMode, setViewMode] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [fileContent, setFileContent] = useState<File | null>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [isReportSavedAlready, setIsReportSavedAlready] = useState(false);
    const [preservePreviewReport, setpreservePreviewReport] = useState<ReportInfo>();

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const handleApiSuccess = () => {
        cont.showNotificationBar(isEditMode ? 'Report updated successfully.' : 'Report saved successfully.', 'success');
        setIsReportSavedAlready(true);
        if (openDialog) {
            // close the report section only with save and close button
            reportListUpdate(isEditMode);
            setOpenDialog(false);
            setOpen(false);
        }
    };

    const { mutate, data, isLoading } = useGQLMutation(
        'CreateReport',
        reportQuery.ADD_REPORT,
        {
            onSuccess: handleApiSuccess,
            onError: handleApiError,
        },
        '/list',
    );

    const { mutate: editMutate, data: editData, isLoading: isUpdating } = useGQLMutation(
        'UpdateReport',
        reportQuery.EDIT_REPORT,
        {
            onSuccess: handleApiSuccess,
            onError: handleApiError,
        },
        '/list',
    );

    const handleClose = () => {
        // Check if the report has changes or if we are in edit mode
        if (runTimeReport.current) {
            // storing report changes before confirmation dialog open
            setpreservePreviewReport({
                id: '',
                displayName: '',
                definition: runTimeReport.current,
                isDirty: false,
            });
            // Show confirmation dialog to save changes
            setOpenDialog(true);
        } else {
            // No changes, close the dialog directly
            setOpen(false);
        }
    };

    const saveReport = (report: ReportType) => {
        // Create/Update reports
        if (isEditMode || isReportSavedAlready) {
            // Update local state to reflect changes in the edited report
            updateEditedReport({
                id: report.id || ((data?.create_report as CreateReportType).id),
                display_name: report.display_name,
                data: report.data,
                module_name: report.module_name,
            });
            editMutate({
                org_id: orgID,
                id: report.id || ((data?.create_report as CreateReportType).id),
                data: report.data,
                display_name: report.display_name,
                module_name: report.module_name,
            });
        } else {
            mutate({
                org_id: orgID,
                data: report.data,
                module_name: report.module_name,
                display_name: report.display_name,
            });
        }
    };

    const getRunTimeReport = (doc: DocumentChangedEventArgs) => {
        runTimeReport.current = doc.definition || doc;
    };

    const exportClick = () => {
        // create file in browser
        const fileName = name;
        const reportObj = runTimeReport.current || JSON.parse(selectedReport?.data || '{}') as ReportInfo['definition'];
        // remove connectionString which has JWT token so it does not get stored to users system
        reportObj.DataSources?.forEach((el) => {
            if (el.ConnectionProperties) {
                // eslint-disable-next-line no-param-reassign
                el.ConnectionProperties.ConnectString = '';
            }
        });
        // P-4-I16 as a part of new improvements
        // storing pathName in the to be saved JSON file, which will be later(while importing) used to validate module.
        const json = JSON.stringify({ ...reportObj, pathName: curLocation.pathname });
        const blob = new Blob([json], { type: 'application/json' });
        const href = URL.createObjectURL(blob);
        // create "a" HTLM element with href to file
        const link = document.createElement('a');
        link.href = href;
        link.download = `${fileName}.json`;
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    };

    const handleImport = () => {
        // Trigger the file input click programmatically
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
            fileInputRef.current.click();
        }
    };

    const processFileContent = (fileContnt: string) => {
        // org level reports cannot be imported in project and vice-versa.
        const reportObject = JSON.parse(fileContnt) as { pathName: string };
        const { pathName } = reportObject;
        if (!pathName) {
            cont.showNotificationBar('Invalid JSON file', 'error');
            return;
        }
        if (curLocation.pathname !== pathName) {
            const message = curLocation.pathname === '/reports'
                ? 'Cannot import project module reports in organisation module.'
                : 'Cannot import organisation module reports in a project module.';
            cont.showNotificationBar(message, 'error');
            return;
        }
        const reportObj = JSON.parse(fileContnt) as ReportInfo['definition'];
        if (reportObj && reportObj.DataSources) {
            reportObj.DataSources.forEach((dSource) => {
                if (dSource.ConnectionProperties) {
                    // eslint-disable-next-line no-param-reassign
                    dSource.ConnectionProperties.ConnectString = `endpoint=${getBackendEndpoint()}`
                    + `;Header$Authorization=Bearer ${getAccessTokenFromLocalStorage() || ''};`;
                }
            });
        }
        reportObj.DataSets?.forEach((dSet) => {
            const datasetName = dSet.Name;
            if (dSet.Query) {
                // eslint-disable-next-line no-param-reassign
                dSet.Query.CommandText = getQueryDetailsByDataset(
                    orgID || '',
                    projectCtx?.project?.id || '',
                    projectCtx?.project?.version_id || '',
                    datasetName,
                    exchangeRate as number,
                );
            }
        });
        // Previously below steps were used to enable save button when a report was imported
        // now we got this method from ActiveReportsJS team, which is much more elegant.
        reportRef?.current?.setReport(
            {
                id: reportRef.current.props.report?.id,
                displayName: reportRef.current.props.report?.displayName,
                definition: reportObj,
            },
            'override',
            true,
        ).catch(() => {});

        // Hack-Workarround
        // Problem: In active reports js, when we import a new report from local system it is not enabling by default
        //          Also there is no value or api reference to enable the save button programatically.
        // Solution:
        //    Step1: Enable the 'Save As' button from document.
        //           This step is not enough as the onclick listner is also removed.
        //    Step2: Add a event listner on 'Save As' button, when click we manually click 'Save As New' button
        //           Note here the 'Save As' and 'Save As New' button does same funtionality
        // const saveButton = document.querySelector("button[data-aid='save-button']");
        // saveButton?.removeAttribute('disabled');
        // saveButton?.addEventListener('click', () => {
        //     const saveAsNewDropDownButton: HTMLButtonElement | null = document
        //         .querySelector('button[data-aid="save-as-dropdown_toggle"]');
        //     saveAsNewDropDownButton?.click();
        //     const saveAsNewButton: HTMLButtonElement | null = document.querySelector('button[title="Save As"]');
        //     saveAsNewButton?.click();
        // });
    };

    const handleConfirm = (confirmed: boolean) => {
        const fileReader = new FileReader();
        if (fileContent && confirmed) {
            fileReader.readAsText(fileContent, 'UTF-8');
            fileReader.onload = (e) => {
                const doc = e?.target?.result;
                if (doc && typeof doc === 'string') {
                    processFileContent(doc);
                }
            };
        }
        setDialogOpen(false);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files && event.target.files[0];
        // bugfix - P-4-I21 added validation to only accept JSON files.
        if (selectedFile?.type !== 'application/json') {
            cont.showNotificationBar('Incorrect file type, only JSON files allowed.', 'error');
            return;
        }
        if (typeof selectedFile === 'object') {
            setFileContent(selectedFile);
            if (runTimeReport.current || isEditMode || selectedReport) {
                setDialogOpen(true);
            } else {
                const fileReader = new FileReader();
                fileReader.readAsText(selectedFile, 'UTF-8');
                fileReader.onload = (e) => {
                    const doc = e?.target?.result;
                    if (doc && typeof doc === 'string') {
                        processFileContent(doc);
                    }
                };
            }
        }
    };

    const handleCancel = (v: boolean) => {
        setDialogOpen(v);
    };

    const handleCancl = () => {
        setOpenDialog(false);
    };

    const handleDiscardChange = () => {
        setOpen(false);
        setOpenDialog(false);
        reportListUpdate(isEditMode);
    };

    // Saving report from Save & Close button
    const handleSave = () => {
        const reportData = preservePreviewReport?.definition;
        let tableHasDataset = true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
        const ReportItems: ReportItem[] = reportData?.ReportSections[0].Body?.ReportItems;
        const DataSets = reportData?.DataSets;
        ReportItems?.forEach((item: ReportItem) => {
            if (item.Type === 'table' && (!DataSets || !DataSets.length)) {
                tableHasDataset = false;
            }
        });
        if (tableHasDataset) {
            // Call mutation API to create report
            saveReport({
                id: (isEditMode || isReportSavedAlready) ? selectedReport?.id || '' : '',
                display_name: name,
                module_name: module,
                data: JSON.stringify(reportData),
            });
        } else {
            cont.showNotificationBar('Adding dataset is mandatory for tables.', 'error');
            setOpen(false);
        }
    };

    return (
        <Box>
            <Loader loading={isLoading || isUpdating} isFromAGGrid />
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open}>
                <DialogTitle className={classes.dilogBoxSection}>
                    <Box className={classes.outlineButton}>
                        { isEditMode ? 'Edit Report' : 'Save New Report' }
                        <Box className={classes.outlineButton}>
                            <Box>
                                <Button variant="outlined" onClick={handleClose}>Close</Button>
                            </Box>
                            <Dialog
                                open={openDialog}
                                onClose={handleCancl}
                                className={classes.confirmPopupDialog}
                            >
                                <DialogTitle className={classes.confirmPopupTitle}>Close Report</DialogTitle>
                                <DialogContent className={classes.confirmPopupContent}>
                                    <DialogContentText>
                                        Do you want to save these changes before closing the report?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions className={classes.confirmPopupButtons}>
                                    <Button onClick={handleCancl} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={handleDiscardChange} color="primary" autoFocus>
                                        Discard Changes
                                    </Button>
                                    <Button onClick={handleSave} color="primary" autoFocus>
                                        Save & Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Box>
                                <Button variant="contained" onClick={exportClick}>Export</Button>
                            </Box>
                            {
                                viewMode ? null
                                    : (
                                        <Box>
                                            <Button variant="contained" onClick={handleImport}>Import</Button>
                                            <input
                                                ref={fileInputRef}
                                                type="file"
                                                accept=".json"
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange}
                                            />
                                        </Box>
                                    )
                            }
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    {/* <Loader loading={isLoading || isUpdating} isFromAGGrid /> */}
                    <Report
                        ref={reportRef}
                        name={name}
                        module={module}
                        saveReport={saveReport}
                        isEditMode={isEditMode}
                        selectedReport={selectedReport}
                        getRunTimeReport={getRunTimeReport}
                        viewMode={viewMode}
                        setViewMode={setViewMode}
                        preservePreviewReport={preservePreviewReport}
                        setpreservePreviewReport={setpreservePreviewReport}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={dialogOpen}
                onClose={() => handleCancel(false)}
                className={classes.confirmPopupDialog}
            >
                <DialogTitle className={classes.confirmPopupTitle}>Confirm Import</DialogTitle>
                <DialogContent className={classes.confirmPopupContent}>
                    <DialogContentText>
                        Are you sure you want to import this file? This will overwrite the current report.
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.confirmPopupButtons}>
                    <Button onClick={() => handleCancel(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleConfirm(true)} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default NewReportPopup;

import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { ProjectContext } from '../../store/context/projectContext';

function NavbarTitle() {
    const projectContext = useContext(ProjectContext);
    const {
        name, estimate_type: estimateType, region, country_name: country, owner, consultant_number: consultantNumber, base_date: baseDate,
    } = projectContext?.project || {};

    let formattedDate = '';
    if (baseDate) {
        const date = new Date(baseDate);
        const year = baseDate?.split('-')[0];
        const month = date.toLocaleDateString('default', { month: 'short' });
        formattedDate = `${month} ${year}`;
    }

    return (
        <Box>
            <Typography variant="h2" noWrap>
                { name }
            </Typography>
            <Typography variant="body1" noWrap>
                {owner}
                {' '}
                /
                {' '}
                {consultantNumber}
                {' '}
                /
                {' '}
                {estimateType}
                {' '}
                /
                {' '}
                {region}
                ,
                {' '}
                {country}
                {' '}
                /
                {' '}
                {formattedDate}
            </Typography>
        </Box>
    );
}
export default NavbarTitle;

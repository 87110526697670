import { makeStyles } from '@mui/styles';

const ProjectMapStyle = makeStyles(() => ({
    imgMap: {
        width: '50%',
        marginLeft: '20%',
        marginTop: '20px',
    },
    mapBox: {
        borderRadius: '6px',
        border: '2px solid #CECECE',
    },
    projectMapWidth: {
        marginTop: '20px',
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: '27vh',
            },
        },
        '& .ag-row-level-0': {
            fontWeight: '600 !important',
        },
    },
    filterDesc: {
        marginBottom: '5px',
        '& span': {
            '&:nth-child(1)': {
                fontSize: '14px',
                fontFamily: 'Inter',
            },
            '&:nth-child(2)': {
                fontSize: '14px',
                fontFamily: 'Inter-Medium',
                color: '#000',
                fontWeight: 600,
            },
        },
        '& button': {
            fontSize: '14px',
            textDecoration: 'underline',
            color: '#007DFF',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
        },
    },
}));

export default ProjectMapStyle;

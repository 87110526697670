import React, { useContext, useState } from 'react';
import useStyles from './PackageHeader.style';
import {
    Box, Button, Input, Typography,
} from '@mui/material';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import capexIcon from '../../../../assets/capexIcon.svg';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddEditPackageModel from '../AddEditPackageModel';
import DeleteRowModel from '../../../ProjectsListing/Components/DeleteRowModel';
import ImportOptionsModel from '../../../ProjectsListing/Components/ImportOptionsModel';
import CostHeaderBar from '../../../../components/costHeaderBar';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { PATH_CMFR } from '../../../../Routes/path';
import ClearIcon from '@mui/icons-material/Clear';
import QuickSearchFilter from '../../../../components/quickSearchFilter/QuickSearchFilter';
import HideUnusedButton from '../../../../components/HideUnusedButton';
import exportExcel from '../../../../helper/ExportExcel';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import { OutletContext } from '../../../../types/OrganisationDetailType';

interface Props {
    search: string,
    searchText: string,
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    setHideUnusedFlag: React.Dispatch<React.SetStateAction<boolean>>;
    hideUnusedFlag: boolean;
    isPackageFetching: boolean;
}

function ResourcesHeader(props: Props) {
    const {
        search, searchText, setSearchText, hideUnusedFlag, setHideUnusedFlag, isPackageFetching,
    } = props;

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openImportOptions, setOpenImportOptions] = useState(false);
    const [openDeleteModel, setOpenDeleteModel] = useState(false);
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const isViewOnly = Number(projectCtx?.project?.permission_id) === 1;
    const cont: OutletContext = useOutletContext();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickImport = () => {
        setOpenImportOptions(true);
    };

    const handleClickDalete = () => {
        setOpenDeleteModel(true);
    };

    const navigateToReports = () => {
        navigate(`${PATH_CMFR.capex.reports}?module=packages`);
    };
    const onFilterTextBoxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };
    const handleClear = () => {
        setSearchText('');
    };
    const handleExportExcel = () => {
        exportExcel({
            activeOrgId: userCtx?.user?.default_org_id,
            grid: 'package',
            activeVersionId: projectCtx?.project?.version_id,
        }).catch((err) => {
            const errorMessage = err instanceof Error ? err.message : 'An error occurred while generating the report';
            cont.showNotificationBar(errorMessage, 'error');
        });
    };

    return (
        <div className={classes.TableHeader}>
            <Box className={classes.inTableHeader}>
                <Typography variant="h2">
                    PACKAGES
                </Typography>
                <CostHeaderBar />
                <Box className={classes.versonUndo}>
                    <Box className={classes.outlineButton}>
                        <Button onClick={navigateToReports}>
                            <DataSaverOffOutlinedIcon />
                            {' '}
                            Reports
                        </Button>
                    </Box>
                </Box>
                <QuickSearchFilter setSearchText={setSearchText} searchText={searchText} />
                {!searchText
                && <HideUnusedButton hideUnusedFlagVal={hideUnusedFlag} setHideUnusedFlag={setHideUnusedFlag} loadingData={isPackageFetching} />}
                {/* <Box className={classes.rightSection}>
                    <Box className={classes.inputBoxStyle}>
                        <Input type="text" value={search} placeholder="Search" />
                        <span><SearchIcon /></span>
                    </Box>
                </Box> */}
            </Box>
            <Box className={classes.rightSection}>
                {/* <Box className={classes.outlineButton}>
                    <Button onClick={handleClickImport}>
                        <CloudDownloadOutlinedIcon />
                        {' '}
                        Import
                    </Button>
                </Box> */}
                {!isViewOnly && (
                    <Box className={classes.outlineButton}>
                        <Button onClick={handleExportExcel}>
                            <CloudUploadOutlinedIcon />
                            {' '}
                            Export
                        </Button>
                    </Box>
                )}
                {/* <Box className={classes.deleteButton}>
                    <Button onClick={handleClickDalete}>
                        <DeleteOutlineOutlinedIcon />
                        {' '}
                        Delete
                    </Button>
                </Box> */}
                {/* <Box className={classes.outlineButton}>
                    <Button>
                        <EditOutlinedIcon />
                        {' '}
                        Edit
                    </Button>
                </Box> */}
                {/* <Box className={classes.newButton}>
                    <Button variant="contained" onClick={handleClickOpen}>
                        <AddIcon />
                        {' '}
                        New
                    </Button>
                </Box> */}
                {open && <AddEditPackageModel open={open} setOpen={setOpen} />}
                {openImportOptions && <ImportOptionsModel open={openImportOptions} setOpenImport={setOpenImportOptions} />}
                {openDeleteModel && <DeleteRowModel open={openDeleteModel} setOpenDelRow={setOpenDeleteModel} deleteAction={() => {}} />}
            </Box>
        </div>
    );
}

export default ResourcesHeader;

import { makeStyles } from '@mui/styles';

const SettingsStyle = makeStyles(() => ({
    container: {
        '& button': {
            textTransform: 'capitalize',
            fontSize: '13px',
            padding: '3.4px 11px !important',
            '& svg': {
                marginTop: '-2px',
                color: '#787878',
            },
        },
    },
    boxwrap: {
        backgroundColor: '#F8F8F8',
        display: 'flex',
        padding: '30px',
        border: '#dadada 1px solid',
        borderRadius: '5px',
        marginTop: '20px !important',
        '& div': {
            width: '20%',
        },
        '& h6': {
            fontSize: '16px',
            lineHeight: '18px',
            fontWeight: '600',
            marginTop: '8px',
            color: '#090909',
        },
        '& span': {
            color: '#969595',
            fontSize: '12px',
        },
        '& hr': {
            marginTop: '100px',
        },
    },
    genInfoWidth: {
        width: '83.34% !important',
    },
    locationWidth: {
        width: '67% !important',
    },
    divider: {
        margin: '21px 0px 21px 0px !important',
        listStyle: 'none',
    },
    spaceBt: {
        marginBottom: '20px',
    },
    chartStyle: {
        marginLeft: '25px',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
        borderRadius: '9.52px',
        padding: '2.5px 12px',
        '& svg': {
            verticalAlign: 'text-bottom',
        },
        '& span': {
            verticalAlign: 'middle',
        },
    },
    checkWidth: {
        width: '30px',
    },
    checkBoxStyle: {
        '& input': {
            height: 12,
            width: 12,
        },
    },
    TableHeader: {
        margin: '0px',
        color: '#1C1C1C',
        padding: '8px 0px 32px 0px',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& h6': {
            fontSize: '21.24px',
            fontWeight: '700',
            textTransform: 'uppercase',
            display: 'flex',
            alignItems: 'center',
            '& span': {
                fontSize: '11px',
                fontWeight: '600',
            },
        },
        '& .donutchart-innertext': {
            visibility: 'hidden',
        },
        '& .donutchart-arcs': {
            '& path': {
                stroke: '#ffffff',
                opacity: '1',
            },
        },
    },
    inTableHeader: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            margin: '0px',
        },
        '& h2': {
            fontWeight: '700',
            color: '#1c1c1c !important',
        },
    },
    heading: {
        fontWeight: '700 !important',
        fontSize: '19.24px !important',
        lineHeight: '2 !important',
    },
    tableCont: {
        borderRadius: '4px 4px 14px 14px',
        border: '1px solid #CECECE',
        // borderBottom: 'none',
        backgroundColor: '#F8F8F8 !important',
        minHeight: '58px',
        maxHeight: '170px',
        overflowY: 'auto',
    },
    tblcurr: {
        width: '100% !important',
        // borderRadius: '4px 4px 14px 14px',
        '& thead': {
            backgroundColor: '#F8F8F8',
            position: 'sticky',
            top: '0px',
        },

        '& thead tr': {
            height: '30px',
        },
        '& tbody tr': {
            height: '28px',
        },
        '& th': {
            borderBottom: '1px solid #CECECE',
            background: 'linear-gradient(0deg, #F3F3F3 0%, rgba(243, 243, 243, 0.00) 100%)',
            borderRight: '1px solid #CECECE',
            borderCollapse: 'collapse',
            textAlign: 'left',
            padding: '0px 10px',
            color: '#8F8F8F',
            fontSize: '13px',
            fontWight: 600,
            fontFamily: 'Inter-Medium',
            '&:last-child': {
                borderRight: 'none',
            },
        },
        '& td': {
            borderBottom: '1px solid #CECECE',
            padding: '0px 10px',
            fontSize: '13px',
            color: '#353535',
            fontWight: 500,
            fontFamily: 'Inter',
        },
    },
    InputHeight: {
        '& div': {
            '& input': {
                height: '24px !important',
                padding: '8.5px 8px 8.5px 10px !important',

            },
            '& input.Mui-disabled': {
                WebkitTextFillColor: '#353535',
            },
        },
    },
    btnAcn: {
        all: 'unset',
        padding: '5px 11px !important',
        marginRight: '10px !important',
        textTransform: 'capitalize',
        position: 'relative',
        color: '#787878 !important',
        border: '#787878 1px solid !important',
    },
    subheading: {
        width: '80%',
        fontSize: '17px !important',
        lineHeight: '0',
        fontWeight: '700 !important',
        marginTop: '30px !important',
        marginBottom: '8px !important',
    },
    boxWrapformat: {
        width: '67%',
        marginTop: '35px',
    },
    bggray: {
        backgroundColor: '#F8F8F8',
        border: '#ddd 1px solid',
        borderBottom: 'none',
        padding: '25px',
        width: '100%',
    },
    forheading: {
        color: '#353535',
        fontSize: '20px !important',
        fontStyle: 'normal',
        fontWeight: '500',
        width: '100%',
    },
    bgwhite: {
        backgroundColor: '#f0f0f0',
        border: '#ddd 1px solid',
        width: '100%',
        borderTop: 'none',
        fontWeight: 'bold',
        padding: '25px',
    },
    versonUndo: {
        '& .MuiSelect-select': {
            padding: '6px',
            fontSize: '18px',
            height: '32px !important',
            borderRadius: '5px !important',
            border: '1px solid #dbdada',
            backgroundColor: '#e9e9e9 !important',
            alignItems: 'center',
            display: 'flex',
        },
        '& .MuiInputBase-root': {
            backgroundColor: '#F8F8F8 !important',
            width: '160px',
            borderRadius: '4px',
            '&:before': {
                border: 'none !important',
            },
            '&:after': {
                border: 'none',
            },
            '&:hover': {
                backgroundColor: '#fff',
            },
        },
        '& .Mui-focused': {
            backgroundColor: '#fff !important',
        },
        '& .MuiInputBase-input': {
            padding: '4px 32px 4px 12px !important',
            fontSize: '12px',
            color: 'rgba(0, 0, 0, 0.57)',
            fontFamily: 'Inter-Medium',
        },
        '& svg': {
            color: '#787878',
        },
    },
    digitext: {
        fontSize: '16px !important',
        marginBottom: '10px !important',
        color: '#353535',
    },
    exptext: {
        color: '#353535',
        fontSize: '14px !important',
    },
    btnIcon: {
        fontSize: '18px !important',
        marginRight: '5px',
    },
    rowBackground: {
        backgroundColor: '#C6FFCA !important',
        '& input[type="checkbox"]': {
            accentColor: '#021948 !important',
        },
    },
    hoverRow: {
        backgroundColor: '#F8F8F8 !important',
        '&:hover': {
            backgroundColor: '#c4f2d2 !important',
        },
    },
    btnSpace: {
        marginRight: '16px !important',
        padding: '5px 8px !important',
    },
    button: {
        textTransform: 'inherit',
    },
    btndelete: {
        color: '#B00000 !important',
        borderColor: '#B00000 !important',
        marginRight: '16px !important',
    },
    undoButtnNew: {
        fontSize: '12px',
        color: '#fff !important',
        border: '1px solid #007DFF !important',
        borderRadius: '4px',
        backgroundColor: '#007DFF !important    ',
        padding: '5px 10px !important',
        cursor: 'pointer',
        textTransform: 'capitalize',
        '& svg': {
            color: '#fff !important',
            fontSize: '15px',
            verticalAlign: 'sub',
            marginRight: '5px',
        },
    },
    currenrversionstl: {
        color: '#007DFF',
    },
    mapContainer: {
        width: '67%',
        '& .leaflet-container': {
            width: '100%',
            height: '600px !important',
        },
        '& .leaflet-bottom': {
            display: 'none',
        },
        '& .leaflet-top': {
            zIndex: '998 !important',
        },
    },
    currencySec: {
        width: '100%',
        height: '100px',
        marginTop: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '#dadada 1px solid',
        borderRadius: '5px',
    },
    tooltipContainer: {
        backgroundColor: '#DA6970',
        border: '0.5px solid #DA6970',
        margin: 'unset',
        padding: '5px',
        '& h6': {
            color: '#fff',
            fontSize: '12px',
            fontWeight: 600,
        },
    },
    outlineButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '4px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
        '& .Mui-disabled': {
            fontSize: '12px',
            color: '#D9D9D9',
            border: '1px solid #D9D9D9',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#D9D9D9',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
    },
}));
export default SettingsStyle;

/* eslint-disable no-nested-ternary */
import {
    Box, Button, FormControl, ListItemIcon, ListItemText, MenuItem, Typography,
    Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import TableHeaderStyle from './TableHeader.style';
import CostHeaderBar from '../../../../components/costHeaderBar';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { PATH_CMFR } from '../../../../Routes/path';
import SelectDropdown from '../../../../components/DropdownSelect';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import exportExcel from '../../../../helper/ExportExcel';
import QuickSearchFilter from '../../../../components/quickSearchFilter/QuickSearchFilter';
import { OutletContext } from '../../../../types/OrganisationDetailType';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteRowModel from '../../../ProjectsListing/Components/DeleteRowModel';
import { ColumnState } from 'ag-grid-community';
import { useUserRole } from '../../../../hooks/useUserRole';
import { ROLES } from '../../../../constants';

// interface Props {
// tittle: string,
// handleClickOpen: () => void;
// handleChange: (event: SelectChangeEvent) => void;
// handleClickOpenDuplicate: () => void;
// }

interface EstimateGridLayoutType {
    id?: string;
    grid_state: string;
    layout_version: string;
    org_id: string;
    project_id: string;
    created_at: string;
    updated_at: string;
  }
  interface GetEstimateGridLayoutType {
    getEstimateGridLayout: {
      data: EstimateGridLayoutType[];
    };
  }

interface MyComponentProps {
    onFilterTextBoxChanged?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    searchText: string,
    gridLayoutData: GetEstimateGridLayoutType,
    setGridView?: React.Dispatch<React.SetStateAction<string>>;
    gridView?: string,
    deleteMutate: (params: { layout_version: string, org_id: string, project_id: string }) => void,
    setLayoutVersion:(p:string) => void,
    layoutVersion?: string,
}

function EstimateHeader(props: MyComponentProps) {
    const classes = TableHeaderStyle();
    // const [gridView, setGridView] = useState('Default View');
    const [openDelettePopup, setOpenDelettePopup] = useState(false);
    const loggedInUserRole = useUserRole();
    const userRole = ROLES.find((role) => role.id === loggedInUserRole);

    const {
        onFilterTextBoxChanged, searchText, setSearchText,
        gridLayoutData, setGridView, gridView, deleteMutate, setLayoutVersion, layoutVersion,
    } = props;
    const navigate = useNavigate();
    const selectMenuOptions = ['Default View'];
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const isViewOnly = Number(projectCtx?.project?.permission_id) === 1;
    const cont: OutletContext = useOutletContext();

    const handleChange = (event: SelectChangeEvent) => {
        setGridView?.(event.target.value);
    };

    const navigateToReports = () => {
        navigate(`${PATH_CMFR.capex.reports}?module=estimate`);
    };
    const handleExportExcel = () => {
        exportExcel({
            activeOrgId: userCtx?.user?.default_org_id,
            grid: 'estimate_detail',
            activeVersionId: projectCtx?.project?.version_id,
        }).catch((err) => {
            const errorMessage = err instanceof Error ? err.message : 'An error occurred while generating the report';
            cont.showNotificationBar(errorMessage, 'error');
        });
    };

    const handleReset = (pr: any) => {
        setOpenDelettePopup(true);
    };

    const handleDeleteLayout = () => {
        deleteMutate({
            layout_version: gridView || '',
            org_id: userCtx?.user?.default_org_id || '',
            project_id: projectCtx?.project?.id || '',
        });
        setGridView?.('');
        // setLayoutVersion?.('');
        setOpenDelettePopup(false);
    };

    useEffect(() => {
        const index = gridLayoutData?.getEstimateGridLayout?.data.findIndex((item) => item.layout_version === layoutVersion);
        setGridView?.(gridLayoutData?.getEstimateGridLayout?.data[index]?.layout_version);
    }, [gridLayoutData?.getEstimateGridLayout?.data]);

    return (
        <div className={classes.TableHeader}>
            <Box className={classes.inTableHeader}>
                <Typography variant="h2">
                    ESTIMATE
                </Typography>
                <CostHeaderBar />
                <Box className={classes.versonUndo}>
                    {/* <Box className={classes.undoButtn}>
                        <button type="submit">
                            <BarChartOutlinedIcon />
                            {' '}
                            Benchmark
                        </button>
                    </Box> */}
                    <Box className={classes.outlineButton}>
                        <Button onClick={navigateToReports}>
                            <DataSaverOffOutlinedIcon />
                            {' '}
                            Reports
                        </Button>
                    </Box>
                    {/* <SelectDropdown view={view} menuOptions={selectMenuOptions} setView={setView} /> */}
                </Box>
                <FormControl variant="filled">
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="View"
                        value={gridView || 'Default Layout'}
                        onChange={handleChange}
                        IconComponent={KeyboardArrowDownIcon}
                        renderValue={(value) => value}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    maxHeight: 300,
                                    '& .MuiMenuItem-root': {
                                        color: '#787878',
                                    },
                                    '& .Mui-selected': {
                                        color: '#021948',
                                        bgcolor: '#E6ECFA !important',
                                    },
                                },
                            },
                        }}
                    >
                        {gridView ? (
                            <MenuItem key="default" value="Default Layout">
                                Default Layout
                            </MenuItem>
                        ) : (
                            <MenuItem key="default" value="Default Layout">
                                Default Layout
                            </MenuItem>
                        )}
                        {userRole?.role === 'Admin' ? (
                            gridLayoutData && gridLayoutData.getEstimateGridLayout && gridLayoutData.getEstimateGridLayout.data ? (
                                gridLayoutData.getEstimateGridLayout.data.map((item: EstimateGridLayoutType) => (
                                    <MenuItem key={item.id} value={item?.layout_version}>
                                        <ListItemText className={classes.selectText} primary={item?.layout_version} />
                                        <ListItemIcon className={classes.deleteIcon}>
                                            <DeleteOutlineIcon onClick={() => handleReset(item)} />
                                        </ListItemIcon>
                                    </MenuItem>
                                ))
                            ) : null
                        ) : (
                            gridLayoutData?.getEstimateGridLayout?.data ? (
                                gridLayoutData.getEstimateGridLayout.data.map((item: EstimateGridLayoutType) => (
                                    <MenuItem key={item.id} value={item?.layout_version}>
                                        <ListItemText className={classes.selectText} primary={item?.layout_version} />
                                    </MenuItem>
                                ))
                            ) : null
                        )}
                    </Select>
                </FormControl>
                <QuickSearchFilter setSearchText={setSearchText} searchText={searchText} />
            </Box>
            {/* <div className="example-header">
                <span>Quick Filter:</span>
                <input
                    type="text"
                    id="filter-text-box"
                    placeholder="Filter..."
                    onChange={onFilterTextBoxChanged}
                />
            </div> */}
            <Box className={classes.versonUndo}>
                {/* <Box className={`${classes.undoButtn} ${classes.deleteButtonStyle}`}>
                    <button type="submit" onClick={handleClickOpenDuplicate}>
                        <DeleteOutlineOutlinedIcon />
                        {' '}
                        Delete
                    </button>
                </Box>
                <Box className={classes.undoButtn}>
                    <button type="submit" onClick={handleClickOpenDuplicate}>
                        <EditOutlinedIcon />
                        {' '}
                        Edit
                    </button>
                </Box>
                <Box className={classes.undoButtn}>
                    <button type="submit" onClick={handleClickOpenDuplicate}>
                        <ContentCopyIcon />
                        {' '}
                        Duplicate
                    </button>
                </Box>
                <Box className={classes.undoButtn}>
                    <button type="submit">
                        <CloudDownloadOutlinedIcon />
                        {' '}
                        Import
                    </button>
                </Box> */}
                {!isViewOnly && (
                    <Box className={classes.outlineButton}>
                        <Button onClick={handleExportExcel}>
                            <CloudUploadOutlinedIcon />
                            {' '}
                            Export
                        </Button>
                    </Box>
                )}
                {/* <Box className={classes.undoButtnNew}>
                    <button type="submit" onClick={handleClickOpen}>
                        <AddIcon />
                        {' '}
                        New
                    </button>
                </Box> */}
            </Box>
            {openDelettePopup && (
                <DeleteRowModel
                    open={openDelettePopup}
                    setOpenDelRow={setOpenDelettePopup}
                    deleteAction={handleDeleteLayout}
                    orgName={gridView}
                    title="Grid Layout"
                />
            )}
        </div>
    );
}

export default EstimateHeader;

import React, {
    Fragment,
    useState,
    useEffect,
    useMemo,
    useContext,
    useRef,
} from 'react';
import CommoditiesHeader from '../../components/CommoditiesHeader';
import { Box } from '@mui/material';
import AgGridComponent from '../../../../components/agGridTableComponent';
import useStyles from './Commodities.style';
import commoditiesQueries from '../../../../queries/commodities';
import { fetchData, useGQLQuery } from '../../../../hooks/useGQLQuery';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import Loader from '../../../../components/Loader';
import CommoditiesListType, {
    GCoaType,
    GetCommoditiesProjectResponse,
    OutletContext,
    QueryErrorResponse,
} from '../../../../types/CommoditiesListType';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import { useNavigate, useOutletContext, Link } from 'react-router-dom';
import { UserContext } from '../../../../store/context/userContext';
import { CellRendererDetailsType, CellRendererType } from '../../../../types/AgGridTypes';
import PageUnavailable from '../../../ProjectSettings';
import { formatCurr, useFormatCurrency } from '../../../../hooks/useFormatCurrency';
import { ProjectContext } from '../../../../store/context/projectContext';
import { useDebounce } from '../../../../hooks/useDebounce';
import { CURR_FORMAT } from '../../../../helper/CountryFlag';
import { GridOptions, RowHeightParams, ValueFormatterParams } from 'ag-grid-community';
import ImportOptionsModel from '../../../ProjectsListing/Components/ImportOptionsModel';
import NotificationBarType from '../../../../types/NotificationBarType';
import { AgGridReact } from 'ag-grid-react';
import { useGQLMutation } from '../../../../hooks/useGQLMutation';
import NotificationBar from '../../../../components/NotificationBar';
import DeleteRowModel from '../../../ProjectsListing/Components/DeleteRowModel';
import { OrganisationContext } from '../../../../store/context/organisationContext';
import { getIndustryLevel } from '../../../../helper/ProjectLevel';
import InvitationType from '../../../../types/InvitationTypes';
import gqlConfig from '../../../../helper/gqlConfig';
import { dateFormat } from '../../../../helper/DateFormat';
import ProjectListType from '../../../../types/ProjectListType';
import { PATH_CMFR } from '../../../../Routes/path';

function CustomGroupRowRenderer(params: ValueFormatterParams) {
    const { node } = params;
    if (node) {
        const rowNode = node;
        const selectColor = () => {
            let color;
            switch (rowNode.field) {
            case 'first_parent':
                color = '#88B0FF';
                break;
            case 'second_parent':
                color = '#AECAFF';
                break;
            case 'self_code_desc':
                color = '#C1D6FF';
                break;
            default:
                color = '#F8F8F8';
                break;
            }
            return color;
        };

        const style = {
            height: '100%',
            backgroundColor: selectColor(),
            fontSize: '12px',
            fontWeight: 700,
            color: '#000',
            lineHeight: '27px',
            paddingLeft: '22px',
        };

        return (
            <div style={style}>
                <span style={style}>
                    <span>
                        {rowNode.key}
                    </span>
                </span>
            </div>
        );
    }
}

interface CommodityData {
    commodities_detail?: {
        data: CommoditiesListType[];
    };
}

interface ParamsData {
    org_id: string;
    generic_coa_id: string;
    id: string;
}

interface GCoaData {
    [key: string]: GCoaType[]; // Index signature for dynamic properties
}

interface CommodityDDOData {
    commodities_ddo?: {
        data: CommoditiesListType[];
    };
}

function Commodities() {
    const classes = useStyles();
    const [pageSkipValue] = useState(0);
    const userCtx = useContext(UserContext);
    const [show] = useState(!!userCtx?.user?.default_org_id);
    const cont: OutletContext = useOutletContext();
    const projectCtx = useContext(ProjectContext);
    const [searchText, setSearchText] = useState('');
    const debouncingValue = useDebounce(searchText);
    const formatCurrency = useFormatCurrency();
    const local: string = CURR_FORMAT[projectCtx?.project?.currency || 'USD'];
    const [hideUnusedFlag, setHideUnusedFlag] = useState(true);
    const gridRef = useRef<AgGridReact<CommoditiesListType>>(null);
    const [selectedCommodities, setSelectedCommodities] = useState<string[]>([]);
    const [notificationDetails, setNotificationDetails] = useState<NotificationBarType>({
        open: false,
        type: 'success',
        message: '',
    });
    const [openDeleteModel, setOpenDeleteModel] = useState(false);
    const [openim, setOpenImport] = useState(false);
    const [footerData, setFooterData] = useState({ data: [{ code: 'Selected Rows: 0/0' }], totalRows: 0 });
    const [commodityData, setCommodityData] = useState<CommodityData>();
    const [rowData, setRowData] = useState<CommoditiesListType[]>([]);
    const masterRefRowData = useRef<CommoditiesListType[] | null>(null);
    const [commodityLevel, setCommodityLevel] = useState(0);
    const [selectCode, setSelectCode] = useState({
        value: '',
        code1: '',
        code: '',
        label: '',
    });
    const [commodityDDOData, setCommodityDDOData] = useState<CommodityDDOData>();
    const gCoaData = useRef<{ [key: string]: GCoaType[] }>({});
    const [masterLoader, setMasterLoader] = useState(false);

    const handleReset = () => {
        // setView('1 Site Developement');
    };
    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0]
            ? response.errors[0].message
            : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const handleApiErrorCommoditiesData = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0]
            ? response.errors[0].message
            : 'API failed';
        cont.showNotificationBar(message, 'error');
        setCommodityLevel(0);
        setRowData([]);
        setFooterData({ data: [{ code: 'Selected Rows: 0/0' }], totalRows: 0 });
    };

    const handleSuccess = (data: InvitationType) => {
        if (data.commodities_detail?.data.length === 0) {
            setCommodityLevel(0);
            setRowData([]);
            setFooterData({ data: [{ code: 'Selected Rows: 0/0' }], totalRows: 0 });
        }
        const updateCommodityData = { ...data };
        masterRefRowData.current = updateCommodityData?.commodities_detail?.data || null;
        setCommodityData(updateCommodityData);
    };

    const isGlobalSearchActive = debouncingValue.length > 0;
    const handleSuccessDDO = (data: InvitationType) => {
        if (data?.commodities_ddo?.data[0]) {
            setCommodityDDOData(data);
            setSelectCode({
                value: data.commodities_ddo.data[0].id,
                code1: data.commodities_ddo.data[0].code1 as string,
                label: data.commodities_ddo.data[0].self_code_desc as string,
                code: data.commodities_ddo.data[0].code,
            });
        } else {
            setCommodityDDOData(undefined);
        }
    };

    const { data, isFetching, refetch } = useGQLQuery(
        `GetCommoditiesWithProjectsDataByOrgId-${userCtx?.user?.default_org_id || ''}`,
        commoditiesQueries.GET_COMMODITIES_WITH_PROJECTS_BY_ORGID(
            userCtx?.user?.default_org_id || '',
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            debouncingValue.length > 0 ? debouncingValue.trim() : debouncingValue,
            isGlobalSearchActive ? false : hideUnusedFlag,
            (debouncingValue.length > 0 ? '' : selectCode.code1),
        ),
        {},
        {
            enabled: show && (debouncingValue.length > 0 ? true : !!selectCode.code1),
            onError: handleApiErrorCommoditiesData,
            cacheTime: 0,
            onSuccess: handleSuccess,
        },
    );

    const { data: commoditiesDDOData, isFetching: ddoFetching, refetch: commoditiesRefetch } = useGQLQuery(
        `GetCommoditiesWithByOrgId-${userCtx?.user?.default_org_id || ''}`,
        commoditiesQueries.GET_COMMODITIES_LIST_BY_ORGID(
            userCtx?.user?.default_org_id || '',
        ),
        {},
        {
            enabled: show,
            onError: handleApiError,
            cacheTime: 0,
            onSuccess: handleSuccessDDO,
        },
    );

    const graphQLClient = gqlConfig('/list');

    useEffect(() => {
        const comData = commodityData?.commodities_detail;
        const tableData = comData?.data;
        if (tableData && tableData.length) {
            const row1 = tableData[0];
            const level = getIndustryLevel([
                row1.code1, row1.code2, row1.code3, row1.code4, row1.code5, row1.code6,
            ]);
            setCommodityLevel(level);
            setRowData(tableData);
            setFooterData({ data: [{ code: `Selected Rows: 0/${tableData.length}` }], totalRows: tableData.length });
        }
    }, [commodityData]);

    // const pageInfo = projectData?.pageInfo;

    const handleSelectCode = (commodity: CommoditiesListType) => {
        if (commodity) {
            setSelectCode({
                value: commodity.id,
                code1: commodity.code1 as string,
                label: commodity.self_code_desc as string,
                code: commodity.code,
            });
        }
    };

    useEffect(() => {
        const isSearchTextEmpty = searchText.length === 0;
        const isSelectCodeEmpty = !selectCode.value;
        const isDebouncingValueNotEmpty = debouncingValue.length > 0;

        if (isSearchTextEmpty && isSelectCodeEmpty) {
            setRowData([]);
            commoditiesRefetch();
            return;
        }

        if (show && !ddoFetching && (!isSelectCodeEmpty || !isSearchTextEmpty)) {
            if (isDebouncingValueNotEmpty) {
                if (!isSelectCodeEmpty) {
                    setSelectCode({
                        value: '',
                        code1: '',
                        code: '',
                        label: '',
                    });
                }
                setCommodityDDOData(undefined);
                setRowData([]);
                gCoaData.current = {};
                refetch();
            } else {
                gCoaData.current = {};
                refetch({ value: selectCode.code1 });
            }
        }
    }, [pageSkipValue, refetch, debouncingValue, hideUnusedFlag, selectCode]);

    useEffect(() => {
        commoditiesRefetch();
    }, []);

    interface CommoditiesResponseType {
        deleteCommodities: {
            error: string,
            error_code: number,
            message: string
        }
    }

    const handleDeleteSuccess = (res: CommoditiesResponseType) => {
        gCoaData.current = {};
        setCommodityData({});
        setRowData([]);
        setCommodityLevel(0);
        setSelectedCommodities([]);
        setSelectCode({
            value: '',
            code1: '',
            code: '',
            label: '',
        });
        setSearchText('');
        commoditiesRefetch();
        setFooterData({ data: [{ code: 'Selected Rows: 0/0' }], totalRows: 0 });
        setNotificationDetails({
            open: true,
            message: res?.deleteCommodities?.message,
            type: 'success',
        });
    };

    const handleDelete = () => {
        setOpenDeleteModel(true);
    };

    const { mutate, isLoading } = useGQLMutation(
        'DeleteCommodities',
        commoditiesQueries.DELETE_COMMODITIES(userCtx?.user?.default_org_id || '', JSON.stringify(selectedCommodities)),
        {
            onSuccess: handleDeleteSuccess,
            onError: handleApiError,
        },
        '/list',
    );

    const onSelectionChanged = () => {
        if (gridRef.current) {
            const gridApi = gridRef.current.api;
            const selectedRows = gridApi?.getSelectedRows();
            if (selectedRows) {
                const updatedFooterData = {
                    ...footerData,
                    data: [
                        {
                            ...footerData.data[0],
                            code: `Selected Rows: ${selectedRows.length}/${footerData.totalRows}`,
                        },
                    ],
                };
                setFooterData(updatedFooterData);
            }
            if (selectedRows && selectedRows.length) {
                const selectedCommoditiesIds = selectedRows.map((org) => org.id);
                setSelectedCommodities(selectedCommoditiesIds);
            } else {
                setSelectedCommodities([]);
            }
        }
    };

    const onDeleteConfirmation = () => {
        mutate({});
        setOpenDeleteModel(false);
    };

    const nameCellRenderer = (params: CellRendererDetailsType) => {
        const colName = params.colDef.field;
        if (params.value != null) {
            switch (colName) {
            case 'description':
                if (params?.data?.level === 1) {
                    return (
                        <span
                            className={commodityLevel === 6 ? classes.headingText : classes.boldText}
                        >
                            {params?.value.toLocaleString()}
                        </span>
                    );
                }
                if (params?.data?.level === 2) {
                    return (
                        <span
                            className={commodityLevel === 2 ? classes.normalText : classes.boldText}
                        >
                                &emsp;
                            {params?.value.toLocaleString()}
                        </span>
                    );
                }
                if (params?.data?.level === 3) {
                    return (
                        <span
                            className={commodityLevel <= 3 ? classes.normalText : classes.boldText}
                        >
                                &emsp;&emsp;
                            {params?.value.toLocaleString()}
                        </span>
                    );
                }
                if (params?.data?.level === 4) {
                    return (
                        <span
                            className={commodityLevel <= 4 ? classes.normalText : classes.boldText}
                        >
                                &emsp;&emsp;&emsp;
                            {params?.value.toLocaleString()}
                        </span>
                    );
                }
                if (params?.data?.level === 5) {
                    return (
                        <span
                            className={commodityLevel <= 5 ? classes.normalText : classes.boldText}
                        >
                                &emsp;&emsp;&emsp;&emsp;
                            {params?.value.toLocaleString()}
                        </span>
                    );
                }
                if (params?.data?.level === 6) {
                    return (
                        <span className={classes.normalText}>
                                &emsp;&emsp;&emsp;&emsp;&emsp;
                            {params?.value.toLocaleString()}
                        </span>
                    );
                }
                return params?.value?.toLocaleString();
            case 'gcoa_unit':
                return `${params.value.toLocaleString()}`;
            case 'gcoa_quantity':
                return params.value && Number((+params.value).toFixed(0)).toLocaleString('en-US');
            case 'gcoa_unit_cost':
                return `$${formatCurr(+((+params.value).toFixed(2)), 'en-US')}`;
            case 'total_cost':
                return `$${formatCurr(+((+((+params.value)).toFixed(0))), 'en-US')}`;
            case 'code':
                if (params?.data.level === 1) {
                    return (
                        <span
                            style={{
                                fontSize: '13px',
                                fontWeight: 700,
                                fontFamily: 'Inter-Medium',
                                textTransform: 'uppercase',
                            }}
                        >
                            {params?.value.toLocaleString()}
                        </span>
                    );
                }
                if (params?.data.level === 2) {
                    return (
                        <span
                            style={{
                                fontSize: '13px',
                                fontWeight: 600,
                                fontFamily: 'Inter-Medium',
                            }}
                        >
                            {params?.value.toLocaleString()}
                        </span>
                    );
                }
                return params?.value.toLocaleString();
            default:
                return params.value;
            }
        }
        if (params.value === null) return '-';
        return '';
    };

    // const rowCellRenderer = (params: CellRendererType) => {
    //     const { value } = params;
    //     const colName = params.colDef.field;
    //     if (value != null) {
    //         if (params?.node?.rowPinned) {
    //             return (
    //                 <span>
    //                     {params?.value.toLocaleString()}
    //                 </span>
    //             );
    //         }
    //         switch (colName) {
    //         case 'total_cost':
    //         case 'total_unit_cost':
    //             return formatCurrency(value);
    //         case 'quantity':
    //             return value && formatCurr(+params.value.toFixed(0), local);
    //         default:
    //             return value;
    //         }
    //     } else if (value === null) {
    //         return '-';
    //     }
    //     return '';
    // };

    const columnDefs = [
        {
            field: 'row_expand ',
            minWidth: 40,
            maxWidth: 40,
            headerName: '',
            cellRenderer: 'agGroupCellRenderer',
            sortable: false,
        },
        // {
        //     field: 'first_parent',
        //     type: 'number',
        //     initialWidth: 130,
        //     headerName: 'Parent 1',
        //     cellRenderer: rowCellRenderer,
        //     hide: true,
        //     // rowGroup: true,
        //     cellRendererParams: {
        //         suppressCount: true,
        //     },
        //     suppressToolPanel: true,
        // },
        // {
        //     field: 'second_parent',
        //     type: 'number',
        //     initialWidth: 130,
        //     headerName: 'Parent 2',
        //     // rowGroup: true,
        //     cellRenderer: rowCellRenderer,
        //     hide: true,
        //     cellRendererParams: {
        //         suppressCount: true,
        //     },
        //     suppressToolPanel: true,
        // },
        // {
        //     field: 'self_code_desc',
        //     type: 'number',
        //     initialWidth: 130,
        //     headerName: 'Commodities Self Desc',
        //     // rowGroup: true,
        //     cellRenderer: rowCellRenderer,
        //     hide: true,
        //     cellRendererParams: {
        //         suppressCount: true,
        //     },
        //     suppressToolPanel: true,
        // },
        {
            field: 'code',
            initialWidth: 150,
            type: 'string',
            headerName: 'Code',
            sort: 'asc',
        },
        {
            field: 'description',
            initialWidth: 350,
            type: 'string',
            headerName: 'Description',
            cellRendererFramework: nameCellRenderer,
            flex: 1.5,
        },
        {
            field: 'unit',
            initialWidth: 130,
            headerName: 'Unit',
            cellRendererFramework: nameCellRenderer,
        },
        {
            field: 'total_cost',
            initialWidth: 180,
            headerName: 'Total Cost',
            cellRendererFramework: nameCellRenderer,
            type: 'numericColumn',
        },
    ];

    const navigate = useNavigate();
    const handleLink = (project:ProjectListType) => {
        const commodityForProject = masterRefRowData.current?.find((com) => com.id === project?.gcoa_id);
        projectCtx?.setProject(project);
        navigate(
            PATH_CMFR.capex.estimate,
            {
                state: {
                    gCoaId: project?.gcoa_id,
                    gcoaCode: commodityForProject?.code,
                    gcoaDescription: commodityForProject?.description,
                },
            },
        );
    };

    const tableNameCellRenderer = (params: CellRendererDetailsType) => {
        const colName = params.colDef.field;
        // console.log(yearValue);
        if (params.value != null) {
            switch (colName) {
            case 'name':
                return (
                    <Link
                        to="/"
                        onClick={(e) => {
                            e.preventDefault();
                            handleLink(params.data);
                        }}
                        style={{ color: '#007DFF' }}
                    >
                        {params.value}
                    </Link>
                );
            case 'gcoa_total_cost':
                return `$${formatCurr(+((+params.value).toFixed(2)), 'en-US')}`;
            case 'base_date':
                return dateFormat(params.value, 'yyyy');
            default:
                return params.value;
            }
        }
        if (params.value === null) return '-';
        return '';
    };

    const detailCellRendererParams = useMemo(() => ({
        detailGridOptions: {
            loading: true,
            columnDefs: [
                {
                    field: 'consultant_number',
                    headerName: 'Project Number',
                    initialWidth: 200,
                    cellRenderer: 'agGroupCellRenderer',
                    lockVisible: true,
                },
                {
                    field: 'name', headerName: 'Name', initialWidth: 150, cellRendererFramework: tableNameCellRenderer,
                },
                { field: 'estimate_type', headerName: 'Estimate Type', initialWidth: 180 },
                { field: 'country_name', headerName: 'Country', initialWidth: 150 },
                { field: 'region', headerName: 'Region', initialWidth: 150 },
                {
                    field: 'base_date', headerName: 'Base Date', initialWidth: 150, cellRendererFramework: tableNameCellRenderer,
                },
                {
                    field: 'owner', headerName: 'Owner', initialWidth: 150, cellRendererFramework: nameCellRenderer,
                },
                // {
                //     field: 'total_cost', headerName: 'Cost', minWidth: 50, cellRendererFramework: tableNameCellRenderer, type: 'numericColumn',
                // },
                {
                    field: 'gcoa_quantity', headerName: 'Quantity', initialWidth: 150, cellRendererFramework: nameCellRenderer, type: 'numericColumn',
                },
                {
                    field: 'gcoa_unit', headerName: 'Unit', initialWidth: 150, initialSort: 'asc', cellRendererFramework: nameCellRenderer,
                },
                {
                    field: 'gcoa_unit_cost',
                    headerName: 'Unit cost',
                    initialWidth: 150,
                    cellRendererFramework: nameCellRenderer,
                    type: 'numericColumn',
                },
                {
                    field: 'gcoa_total_cost',
                    headerName: 'Cost',
                    initialWidth: 150,
                    cellRendererFramework: tableNameCellRenderer,
                    type: 'numericColumn',
                },
            ],
            defaultColDef: {
                // flex: 1,
                sortable: true,
                unSortIcon: true,
                suppressMenu: false,
                resizable: true,
                suppressMovable: true,
                filter: 'agMultiColumnFilter',
            },
            getContextMenuItems: () => ['copy', 'copyWithHeaders', 'export'],
            masterDetail: false,
            getRowHeight: (params: RowHeightParams) => 27,
            suppressLoadingOverlay: true,
            loadingOverlayComponent: null,
            onRowDataUpdated: (params: GridOptions) => {
                const allColumns = params?.columnApi?.getAllColumns();
                if (allColumns) {
                    const allColumnIds = allColumns.map((column) => column.getId());
                    params?.columnApi?.autoSizeColumns(allColumnIds);
                }
            },
        },
        getDetailRowData(params: { successCallback: (arg0: any) => void; data: { projects: any; }; }) {
            if ('id' in params.data) {
                const orgId: string = userCtx?.user?.default_org_id || '';
                const genericCoaId = (params?.data?.id ? params.data.id : '') as string;
                const key = `${orgId}-${genericCoaId}`;
                if (gCoaData.current[key]) {
                    params.successCallback(gCoaData.current[key]);
                } else {
                    const query = commoditiesQueries.GET_GCOA_BY_COMMODITY_ID(orgId, genericCoaId);
                    setMasterLoader(true);
                    fetchData(graphQLClient, query, {}).then((response) => {
                        const typedResponse = response as { getCommoditiesProject: GetCommoditiesProjectResponse };
                        gCoaData.current[key] = typedResponse.getCommoditiesProject.data;
                        const masterDetData = typedResponse.getCommoditiesProject.data;
                        const updatedData = masterDetData && masterDetData.length ? masterDetData.map((item) => ({
                            ...item,
                            base_date: item.base_date,
                        })) : [];
                        // if ('code' in params.data && 'description' in params.data) {
                        //     masterRefRowData.current = {
                        //         code: params?.data?.code as string || '',
                        //         description: params.data.description as string || '',
                        //     };
                        // }
                        params.successCallback(updatedData);
                        setMasterLoader(false);
                    }).catch((err:Error) => {
                        params.successCallback([]);
                        setMasterLoader(false);
                        cont.showNotificationBar(err.message.substring(0, 60), 'error');
                    });
                }
            } else {
                params.successCallback([]);
            }
        },
    }), []);

    const isCommodities = true;
    // const rowData = tableData && tableData.length ? tableData : [];
    // useEffect(() => {
    //     const total = rowData.length;
    //     setFooterData({ data: [{ code: `Selected Rows: 0/${total}` }], totalRows: total });
    // }, [rowData]);

    if (!show) {
        return (<PageUnavailable />);
    }

    const handleClickOpenImport = () => {
        setOpenImport(true);
    };

    return (
        <Fragment>
            <CommoditiesHeader
                setSearchText={setSearchText}
                searchText={searchText}
                hideUnusedFlag={hideUnusedFlag}
                setHideUnusedFlag={setHideUnusedFlag}
                handleClickOpenImport={handleClickOpenImport}
                handleDelete={handleDelete}
                selectedCommodities={selectedCommodities}
                commodityData={commodityDDOData}
                handleSelectCode={handleSelectCode}
                selectCode={selectCode}
                isGlobalSearchActive={isGlobalSearchActive}
                isCommoditiesLoading={isFetching}
            />
            <Box className={classes.tableSec}>
                <AgGridComponent
                    gridRef={gridRef}
                    isCommodities={isCommodities}
                    columnDefs={columnDefs}
                    rowData={rowData}
                    changeSortingValue={() => { }}
                    masterDetail
                    detailCellRendererParams={detailCellRendererParams}
                    onSelectionChanged={onSelectionChanged}
                    isStatusBarEnabled
                    isToolPanelsEnabled
                    isClipboardEnabled
                    isExportEnabled
                    isGroupable
                    isRangeSelectable
                    isRangeHandle
                    isPinnable
                    moduleName="commodities"
                    setSearchText={setSearchText}
                    // customGroupRowRenderer={CustomGroupRowRenderer}
                    // defaultExpanded={3}
                    rowSelection="multiple"
                    handleReset={handleReset}
                    pinnedBottomRowData={footerData.data}
                    IndCommoRowBGStyle
                    IndCommoLevel={commodityLevel}
                    // colFormat={['total_cost']}
                />
                <Loader loading={isFetching || ddoFetching || masterLoader || isLoading} />
                <NotificationBar
                    open={notificationDetails.open}
                    setOpen={(newState) => setNotificationDetails({ ...notificationDetails, open: newState })}
                    type={notificationDetails.type}
                    message={notificationDetails.message}
                />
                {openDeleteModel && (
                    <DeleteRowModel
                        open={openDeleteModel}
                        setOpenDelRow={setOpenDeleteModel}
                        deleteAction={onDeleteConfirmation}
                        title="Commodities"
                    />
                )}
                {openim && (
                    <ImportOptionsModel
                        open={openim}
                        setOpenImport={setOpenImport}
                        setSearchText={setSearchText}
                        refetchIndustries={commoditiesRefetch}
                    />
                )}
            </Box>
        </Fragment>
    );
}

export default Commodities;

import React, { useEffect, useRef, useState } from 'react';
import { CellRendererType } from '../../../../types/AgGridTypes';
// import { ColumnRowGroupChangedEvent } from 'ag-grid-community';

function CheckboxHeader(params: CellRendererType) {
    const [isChecked, setIsChecked] = useState(false);
    const [isIndeterminate, setIsIndeterminate] = useState(false);
    const checkboxRef = useRef<HTMLInputElement>(null);
    // const [isGrouped, setIsGrouped] = useState(false);

    useEffect(() => {
        const updateCheckboxState = () => {
            const selectedCount = params?.api.getSelectedNodes().length;
            const displayedCount = params?.api.getDisplayedRowCount();
            // On partial selection isIndeterminate will be true, on full selection isChecked will be true
            setIsChecked(selectedCount > 0 && selectedCount === displayedCount);
            setIsIndeterminate(selectedCount > 0 && selectedCount < displayedCount);
        };

        const clearAllSelection = () => params?.api.deselectAll();

        // if isGrouping is true header checkbox will be unClickable
        // const grouping = (event: ColumnRowGroupChangedEvent) => {
        //     params?.api.deselectAll();
        //     if (event.columns?.length) {
        //         setIsGrouped(true);
        //     } else {
        //         setIsGrouped(false);
        //     }
        // };

        params?.api.addEventListener('selectionChanged', updateCheckboxState);
        params?.api.addEventListener('filterChanged', clearAllSelection);
        // params?.api.addEventListener('columnRowGroupChanged', grouping);

        return () => {
            params?.api.removeEventListener('selectionChanged', updateCheckboxState);
            params?.api.removeEventListener('filterChanged', clearAllSelection);
            // params?.api.removeEventListener('columnRowGroupChanged', grouping);
        };
    }, [params?.api]);

    useEffect(() => {
        if (checkboxRef.current) {
            checkboxRef.current.checked = isChecked;
            checkboxRef.current.indeterminate = isIndeterminate;
        }
    }, [isChecked, isIndeterminate]);

    const toggleSelect = (event: React.ChangeEvent<HTMLInputElement>) => (event.target.checked
        ? params?.api.selectAllFiltered() : params?.api.deselectAll());

    return (
        <div className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper 
        ${isIndeterminate ? ' ag-indeterminate' : ' '} ${isChecked ? ' ag-checked' : ' '}`}
        >
            <input
                type="checkbox"
                checked={isChecked}
                // disabled={isGrouped}
                onChange={toggleSelect}
                ref={checkboxRef}
                aria-label="Select all"
                id="customCheckBoxHeader"
            />
        </div>
    );
}

export default CheckboxHeader;

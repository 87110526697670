/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState } from 'react';
import DonutChartStyle from './DonutChart.style';
import {
    PieChart, Pie, Cell, Legend, ResponsiveContainer, Sector,
} from 'recharts';
import { useDisplayCurrFormatter } from '../../hooks/useFormatCurrency';

interface DonutData {
    label: string,
    value: number,
}

interface CellDataType {
    chartData?: DonutData[],
    innerRd?: number,
    outerRd?: number,
    inHeader?: boolean,
    height?: number,
}

// Custom active shape function
const renderActiveShape = (props: any, formatCurrency: (value : number | string) => string) => {
    const RADIAN = Math.PI / 180;
    const {
        cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
        fill, payload, percent, value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx + 200} y={5} dy={8} textAnchor="middle" fill={fill}>
                {payload.label}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 12 : -12)} y={ey} textAnchor={textAnchor} fill="#333">
                {formatCurrency(+value)}
            </text>
            <text x={ex + (cos >= 0 ? 12 : -12)} y={ey + 18} textAnchor={textAnchor} fill="#999">
                {`(${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

function CustomActiveShapePieChart({
    chartData, innerRd, outerRd, inHeader, height,
}: CellDataType) {
    const [activeIndex, setActiveIndex] = useState<number>(0);

    const colorArr = ['#4E73BE', '#DF8244', '#7EAA55', '#A5A5A5', '#937424', '#699AD0', '#f6c142'];
    const headerBarColors = ['#35B83D', '#484DFF'];
    const classes = DonutChartStyle();
    const chartLegend = !inHeader;
    const innrRadi = innerRd || 70;
    const outerRadi = outerRd || 90;
    const centerCoordinate = '50%';
    const formatCurrency = useDisplayCurrFormatter();

    // inHeader Flag used to apply styles in header bar donut chart
    const colors = inHeader ? headerBarColors : colorArr;

    // Function to handle active sector on hover
    const onPieEnter = (_: any, index: number) => {
        setActiveIndex(index);
    };

    return (
        <div className={inHeader ? classes.headerBarchartStyle : classes.chartSec}>
            {chartData && (
                <ResponsiveContainer width="100%" height={height}>
                    <PieChart>
                        <Pie
                            activeIndex={activeIndex}
                            activeShape={(props: any) => renderActiveShape(props, formatCurrency)}
                            data={chartData}
                            dataKey="value"
                            nameKey="label"
                            cx={centerCoordinate}
                            cy="50%"
                            innerRadius={innrRadi}
                            outerRadius={outerRadi}
                            fill="#8884d8"
                            onMouseEnter={onPieEnter}
                        >
                            {chartData?.map((entry, index) => (
                                <Cell key={entry.value} style={{ outline: 'none' }} fill={colors[index % colors.length]} />
                            ))}
                        </Pie>
                        {/* {chartLegend && chartData && (
                            <Legend
                                layout="vertical"
                                iconType="rect"
                                align="right"
                                verticalAlign="top"
                                payload={chartData?.map((item, index) => ({
                                    id: item.label,
                                    type: 'square',
                                    value: `${item?.label} - ${formatCurrency(+(item.value))}`,
                                    color: colors[index % colors.length],
                                }))}
                            />
                        )} */}
                    </PieChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}

export default CustomActiveShapePieChart;

import React from 'react';
import DonutChartStyle from './DonutChart.style';
import {
    PieChart, Pie, Cell, ResponsiveContainer, Tooltip,
} from 'recharts';
import { Typography } from '@mui/material';

interface DonutData {
    label: string,
    value: number,
}
interface CellDataType {
    chartData?: DonutData [],
    innerRd?: number,
    outerRd?: number,
    inHeader?: boolean,
    height?: number,
}
interface ChartData {
    label: string,
    value: number,
    percent: string,
}
interface CustomTooltipProps {
    active?: boolean;
    data?: ChartData[];
}
let total = 0;

function CustomTooltip({ active, data }: CustomTooltipProps) {
    const classes = DonutChartStyle();
    if (active && data && data.length) {
        return (
            <div className={classes.tooltip}>
                <Typography>
                    <strong>{data[0].label}</strong>
                    {' '}
                    :
                    {' '}
                    {' '}
                    {data[0].percent}
                </Typography>
                <Typography>
                    <strong>{data[1].label}</strong>
                    {' '}
                    :
                    {' '}
                    {' '}
                    {data[1].percent}
                </Typography>
            </div>
        );
    }
    return null;
}

function DonutChart({
    chartData, innerRd, outerRd, inHeader, height,
}: CellDataType) {
    const colorArr = ['#4E73BE', '#DF8244', '#7EAA55', '#A5A5A5', '#937424', '#699AD0', '#f6c142'];
    const headerBarColors = ['#35B83D', '#484DFF'];
    const classes = DonutChartStyle();
    const innrRadi = innerRd || 70;
    const outerRadi = outerRd || 90;
    const centerCoordinate = '50%';
    // inHeader Flag used to apply styles in header bar donut chart
    const colors = inHeader ? headerBarColors : colorArr;
    total = chartData && chartData.length > 1 ? chartData[0].value + chartData[1].value : 0;

    // Convert to percentage
    const percentageData = chartData?.map((item) => ({
        label: item.label,
        value: item.value,
        percent: `${((item.value / total) * 100).toFixed(2)}%`,
    }));

    return (
        <div className={inHeader ? classes.headerBarchartStyle : classes.chartSec}>
            {percentageData && (
                <ResponsiveContainer width="100%" height={height}>
                    <PieChart>
                        <Pie
                            data={percentageData}
                            dataKey="value"
                            nameKey="label" // Use "label" to match the data's label key
                            cx={centerCoordinate}
                            cy="50%"
                            innerRadius={innrRadi}
                            outerRadius={outerRadi}
                            fill="#8884d8"
                        >
                            {percentageData?.map((entry, index) => (
                                <Cell key={entry.percent} style={{ outline: 'none' }} fill={colors[index % colors.length]} />
                            ))}
                        </Pie>

                        {/* Add custom tooltip */}
                        <Tooltip content={<CustomTooltip data={percentageData} />} />
                    </PieChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}

export default DonutChart;

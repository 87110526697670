import React, { useEffect } from 'react';
import {
    Box, FormControl, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import useStyles from '../../screens/UnitRate/pages/UnitRateHomePage/UnitRateHome.style';
import { ChevronLeft, ChevronRight, KeyboardArrowDown } from '@mui/icons-material';

interface MenuOption {
    value: string,
    code?: string,
    label?: string,
}

type Props = {
    view: MenuOption | string,
    menuOptions: (MenuOption | string)[],
    handleChange?: (p: string) => void,
    handleArrow?: (type: string) => void;
    setView?: (p: string) => void,
    disabled?: boolean,
    nestedUnitCodeData?: string,
};

function SelectDropdown(props: Props) {
    const classes = useStyles();
    const {
        view, menuOptions, handleChange, handleArrow, setView, disabled, nestedUnitCodeData,
    } = props;

    const isMenuOption = (option: MenuOption | string): option is MenuOption => (option as MenuOption).value !== undefined;

    const selectHandleChange = (event: SelectChangeEvent<string>) => {
        if (typeof menuOptions[0] === 'string' && setView) {
            setView(event.target.value);
        } else if (handleChange) {
            handleChange(event.target.value);
        }
    };

    useEffect(() => {
        if (nestedUnitCodeData) {
            handleChange?.(nestedUnitCodeData);
        }
    }, [nestedUnitCodeData]);

    const handleNextBack = (step: string) => {
        if (handleArrow) handleArrow(step);
    };

    const viewIndex = menuOptions.findIndex((option) => isMenuOption(option) && option.value === (typeof view === 'string' ? view : view.value));
    const isDisabled = disabled || (typeof view === 'string' ? view === '' : view.code === '');

    return (
        <Box className={classes.dropdownCont}>
            <div
                role="presentation"
                className={`${menuOptions && viewIndex < 1
                    ? classes.disabledClass : ''} ${classes.arrowCont}`}
                onClick={() => handleNextBack('back')}
            >
                <ChevronLeft />
            </div>
            <FormControl variant="filled">
                <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={typeof view === 'string' ? view : view.value}
                    // eslint-disable-next-line no-nested-ternary
                    renderValue={() => (typeof view === 'string' ? view : (view.code ? view.code : view.value))}
                    onChange={selectHandleChange}
                    IconComponent={KeyboardArrowDown}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                maxHeight: 300,
                                width: 300,
                                '& li': {
                                    width: 300,
                                    display: 'inline-block',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                },
                                '& .MuiMenuItem-root': {
                                    color: '#787878',
                                },
                                '& .Mui-selected': {
                                    color: '#021948',
                                    bgcolor: '#E6ECFA !important',
                                },
                            },
                        },
                    }}
                    disabled={isDisabled}
                >
                    {menuOptions && menuOptions.map((item) => (
                        <MenuItem
                            key={isMenuOption(item) ? item.value : item}
                            value={isMenuOption(item) ? item.value : item}
                        >
                            {isMenuOption(item) ? item.label : item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div
                role="presentation"
                className={`${menuOptions && viewIndex === menuOptions.length - 1
                    ? classes.disabledClass : ''} ${classes.arrowCont}`}
                onClick={() => handleNextBack('next')}
            >
                <ChevronRight />
            </div>
        </Box>
    );
}

export default SelectDropdown;

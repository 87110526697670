import React, {
    Dispatch, SetStateAction, useState, useContext, useEffect, useRef,
} from 'react';
import {
    Box, Typography, DialogContent, DialogTitle,
    Dialog, Grid, Button, TextField, DialogActions,
} from '@mui/material';
import SaveGridStyles from './SaveGridLayout.styles';
import { ColumnState } from 'ag-grid-community';
import { ProjectContext } from '../../../../store/context/projectContext';
import { UserContext } from '../../../../store/context/userContext';

interface EstimateGridLayoutType {
    id?: string;
    grid_state: string;
    layout_version: string;
    org_id: string;
    project_id: string;
    created_at: string;
    updated_at: string;
  }
  interface GetEstimateGridLayoutType {
      data: EstimateGridLayoutType[];
  }

interface MutateType {
    layout_version: string,
    org_id: string,
    project_id: string,
    module_name?: string,
    grid_state?: string,
}

interface GridLayoutData {
    getEstimateGridLayout: GetEstimateGridLayoutType;
  }

interface Props {
    open: boolean,
    gridView?: string,
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    gridState?: GetEstimateGridLayoutType,
    handleOpen?: (p: boolean) => void,
    mutate: (params: MutateType) => void;
    setLayoutVersion?:(p: string) => void
    layoutVersion?: string,
    gridLayoutData: GetEstimateGridLayoutType,
}

function RestoreVersionModal(props: Props) {
    const classes = SaveGridStyles();
    const {
        open, setOpen, gridState, gridView, handleOpen, mutate, setLayoutVersion, layoutVersion, gridLayoutData,
    } = props;
    const [saveView, setSaveView] = useState(true);
    const projectCtx = useContext(ProjectContext);
    const userCtx = useContext(UserContext);

    useEffect(() => {
        if (gridView) {
            setLayoutVersion?.(gridView);
        }
    }, [gridView]);

    const saveLayout = () => {
        mutate({
            grid_state: JSON.stringify(gridState),
            project_id: projectCtx?.project?.id || '',
            org_id: userCtx?.user?.default_org_id || '',
            layout_version: layoutVersion || '',
            module_name: 'estimate',
        });
        setSaveView(true);
    };
    const handleSave = () => {
        const filteredLayouts: EstimateGridLayoutType[] = (gridLayoutData as unknown as GridLayoutData)?.getEstimateGridLayout?.data.filter(
            (item: EstimateGridLayoutType) => item?.layout_version === layoutVersion,
        );
        if (gridView === layoutVersion || filteredLayouts.length > 0) {
            if (gridView === layoutVersion && gridView) {
                setLayoutVersion?.(gridView);
            }
            setSaveView(false);
        } else {
            saveLayout();
            setOpen?.(false);
            // setLayoutVersion?.('');
        }
    };

    // Handler for text field value change
    const handleLayoutNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLayoutVersion?.(event.target.value);
    };

    const handleClose = () => {
        setOpen?.(false);
    };

    const handleOverwrite = () => {
        saveLayout();
        setOpen?.(false);
    };

    useEffect(() => {
        if (!layoutVersion || layoutVersion === 'Default Layout') {
            setSaveView(true);
        }
    }, [layoutVersion]);

    const selectedOrgName = (userCtx?.user?.orgnizations?.length)
        && userCtx.user.orgnizations.find((org) => org.id === userCtx?.user?.default_org_id);

    return (
        <Box>
            <Dialog fullWidth onClose={handleClose} className={classes.mainDilogBoxSection} open={open}>
                <DialogTitle className={classes.dilogBoxSection}>Save Grid Layout</DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    <Grid container spacing={2} className={classes.sectionBody}>
                        <Grid item xs={12}>
                            {saveView ? (
                                <>
                                    <Typography variant="subtitle2" className={classes.labelText}>
                                        Name
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        color="secondary"
                                        placeholder="Layout"
                                        value={(layoutVersion === 'Default Layout' ? '' : layoutVersion)}
                                        onChange={handleLayoutNameChange}
                                    />
                                    <Typography className={classes.text}>
                                        {selectedOrgName
                             && typeof selectedOrgName !== 'number'
                                            ? `Grid layouts are shared with all the members of ${selectedOrgName.name} organization.`
                                            : 'the organization.'}
                                    </Typography>
                                </>
                            ) : (
                                <Typography variant="h3" className={classes.warning}>
                                    <span className={classes.textHead}>Warning : </span>
                                    A grid layout with the name
                                    {' '}
                                    {layoutVersion}
                                    {' '}
                                    already exists. Do you want to overwrite?
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    {saveView ? (
                        <>
                            <Box className={classes.outlineButton}>
                                <Button variant="outlined" onClick={() => handleOpen?.(false)}>Cancel</Button>
                            </Box>
                            <Box className={classes.filledButton}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!layoutVersion
                                    || (layoutVersion === 'Default Layout')}
                                    onClick={handleSave}
                                >
                                    Save
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box className={classes.outlineButton}>
                                <Button variant="outlined" onClick={() => setSaveView(true)}>Rename</Button>
                            </Box>
                            <Box className={classes.deleteButton}>
                                <Button variant="outlined" onClick={handleOverwrite}>Overwrite</Button>
                            </Box>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default RestoreVersionModal;

import { makeStyles } from '@mui/styles';

const ProjectAssignmentModalStyle = makeStyles(() => ({
    mainDilogBoxSection: {
        '& .MuiPaper-root': {
            borderRadius: '10px !important',
            minWidth: '70%',
            padding: '0px !important',
        },
    },
    tablePaginationBar: {
        margin: '-8px 20px 10px 0px',
        '& div': {
            '& div': {
                backgroundColor: '#ffffff',
            },
        },
    },
    dilogBoxSection: {
        borderBottom: '1px solid #CECECE',
        padding: '10px 32px !important',
        fontSize: '20px !important',
        fontWeight: '600 !important',
    },
    dilogBoxSectionFooter: {
        padding: '10px 32px !important',
        borderTop: '1px solid #CECECE',
        '& button': {
            textTransform: 'capitalize',
            boxShadow: 'none',
            fontSize: '16px',
            padding: '7px 32px',
        },
    },
    outlineButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
    },
    filledButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#fff',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: '#007DFF',
            boxShadow: `0px 2.85714px 4.7619px -1.90476px rgba(0, 0, 0, 0.2), 0px 1.90476px 2.85714px rgba(0, 0, 0, 0.14),
             0px 0.952381px 4.7619px rgba(0, 0, 0, 0.12)`,
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '&:hover': {
                backgroundColor: '#007DFF !important',
            },
            '& svg': {
                color: '#fff',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '5px',
            },
        },
    },
    selectBoxStyle: {
        '& .MuiFormControl-root': {
            marginLeft: '15px',
        },
        '& .MuiSelect-select': {
            padding: '6px',
            fontSize: '12px',
            fontWeight: 55,
            height: '20px !important',
            border: '1px solid #C6C6C6',
            backgroundColor: '#ffffff !important',
            alignItems: 'center',
            display: 'flex',
            color: '#007DFF !important',
        },
        '& .MuiInputBase-root': {
            backgroundColor: '#fff !important',
            width: '100px',
            borderRadius: '2px',
            '&:before': {
                border: 'none !important',
            },
            '&:after': {
                border: 'none',
            },
            '&:hover': {
                backgroundColor: '#fff',
            },
        },
        '& .Mui-focused': {
            backgroundColor: '#fff !important',
            border: '1px solid #C6C6C6 !important',
        },
        '& .MuiInputBase-input': {
            padding: '4px 32px 4px 12px !important',
            fontSize: '12px',
            color: 'rgba(0, 0, 0, 0.57)',
            fontFamily: 'Inter-Medium',
        },
        '& svg': {
            color: '#007DFF',
        },
    },
    inputBoxStyle: {
        float: 'right',
        marginLeft: '15px',
        '& Input': {
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'white',
            padding: '13px 20px',
            cursor: 'text',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            letterSpacing: '0.7px',
            textAlign: 'center',
        },
        '& svg': {
            position: 'absolute',
            top: '50%',
            left: '5px',
            transform: 'translateY(-50%)',
            color: '#787878 !important',
            fontSize: '17px',
        },
    },
    tableStyles: {
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: '450px !important',
            },
        },
        '& .ag-input-wrapper': {
            fontSize: '13px',
        },
        '& .ag-root-wrapper': {
            border: '1px solid #CECECE',
            borderRadius: '4px',
        },
        '& .ag-header': {
            minHeight: '31px !important',
            height: 'auto !important',
            background: 'linear-gradient(0deg, #F3F3F3 0%, rgba(243, 243, 243, 0) 100%)',
            borderBottom: '1px solid #CECECE',
        },
        '& .ag-header-cell': {
            borderRight: '1px solid #cecece',
        },
        '& .ag-header-cell-text': {
            color: '#8F8F8F',
            fontSize: '13px',
            fontWeight: '600',
            fontFamily: 'Inter !important',
            marginTop: 8,
        },
        '& .ag-cell': {
            color: '#353535',
            fontSize: '13px',
            fontWeight: '500',
            fontFamily: 'Inter !important',
        },
        '& .ag-row-selected::before': {
            backgroundColor: '#C6FFCA !important',
        },
        '& .ag-row-hover': {
            backgroundColor: '#C6FFCA !important',
        },
        '& .ag-checkbox-input-wrapper': {
            width: 12,
            height: '12px !important',
            marginTop: 8,
        },
        '& .ag-header-cell-comp-wrapper': {
            marginBottom: 8,
        },
        '& .ag-checkbox-input-wrapper.ag-checked::after': {
            color: '#021948 !important',
            marginTop: '-1px',
        },
        '& .ag-checkbox-input-wrapper.ag-indeterminate::after': {
            color: '#021948 !important',
        },
        // '& .ag-ltr .ag-right-aligned-cell': {
        //     // textAlign: 'unset !important',
        // },
        // '& .ag-right-aligned-header .ag-header-cell-label': {
        //     // flexDirection: 'row !important',
        // },
    },
}));

export default ProjectAssignmentModalStyle;

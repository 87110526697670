import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const FETCH_REPORT_DETAILS = (skip: number, take: number, orgId: string, module_name: string[]) => gql`
    query {
        reports(
          skip: ${skip}
          take: ${take}
          filter: { org_id: ${addEscapeCharacters(orgId)}, module_name: [${module_name.map((item) => `"${item}"`).join(',')}] }
        ) {
          data {
            id
            data
            module_name
            display_name
          }
          pageInfo {
            skip
            take
            totalcount
            hasNextPage
          }
        }
      }
    `;

const VALIDATE_REPORT_NAME = (orgId: string, display_name: string) => gql`
  query {
    check_report_name(org_id: ${addEscapeCharacters(orgId)}, display_name: ${addEscapeCharacters(display_name)}){
      message
    }
  }
`;

const ADD_REPORT = gql`
mutation ($org_id: String!, $data: String!, $module_name: String!, $display_name: String!){
    create_report(
      input: {
        org_id: $org_id,
        data: $data,
        module_name: $module_name,
        display_name: $display_name,
      }
    ) {
      successMessage
      id
    }
  }
`;

const EDIT_REPORT = gql`
mutation ($org_id: String!, $id: String!, $data: String!, $display_name: String!, $module_name: String!){
    update_report(
      input: {
        org_id: $org_id,
        data: $data,
        id: $id,
        display_name: $display_name,
        module_name: $module_name,
      }
    ) {
      successMessage
    }
  }
`;

const RENAME_REPORT = gql`
mutation ($org_id: String!, $id: String!, $display_name: String!, $module_name: String!){
    rename_report(
      input: {
        org_id: $org_id,
        id: $id,
        display_name: $display_name
        module_name: $module_name
      }
    ) {
      successMessage
    }
  }
`;

const DELETE_REPORT = gql`
mutation ($org_id: String!, $id: String!){
  delete_report(
      input: {
        org_id: $org_id,
        id: $id
      }
    ) {
      successMessage
    }
  }
`;

export default {
    FETCH_REPORT_DETAILS, ADD_REPORT, DELETE_REPORT, EDIT_REPORT, RENAME_REPORT, VALIDATE_REPORT_NAME,
};

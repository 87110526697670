import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const SAVE_GRID_LAYOUT = gql`
    mutation ($module_name: String!, $grid_state: String!){
      saveGrid (
        module_name: $module_name
        grid_state: $grid_state
      ){
        message
      }  
}`;

const GET_GRID_LAYOUT = (module_name: string) => {
    const moduleName = module_name ? `module_name: ${addEscapeCharacters(module_name)}` : 'module_name: ""';
    return gql`
    query{
      getGridLayout(
        ${moduleName}
      ) {
        data {
          grid_state
          created_at
          updated_at
          module_name
        }
      }
    }`;
};

const RESET_GRID_LAYOUT = gql`
  mutation ResetGridLayout($module_name: String!) {
    resetGrid(module_name: $module_name) {
      message
    }
  }
`;

const SAVE_ESTIMATE_GRID_LAYOUT = gql`
    mutation ($module_name: String!, $grid_state: String!, $layout_version: String!, $project_id: String!, $org_id: String!){
      estimateSaveGrid (
        module_name: $module_name
        grid_state: $grid_state
        layout_version: $layout_version
        org_id: $org_id
        project_id: $project_id
      ){
        message
      }  
}`;

const RESET_ESTIMATE_GRID_LAYOUT = gql`
    mutation ($grid_state: String!, $layout_version: String!, $project_id: String!, $org_id: String!){
      resetEstimateGrid (
        grid_state: $grid_state
        layout_version: $layout_version
        org_id: $org_id
        project_id: $project_id
      ){
        message
      }  
}`;

const GET_GRID_ESTIMATE_LAYOUT = (project_id: string, org_id: string) => gql`
query{
  getEstimateGridLayout(
    project_id: ${addEscapeCharacters(project_id)}
    org_id: ${addEscapeCharacters(org_id)}
  ) {
    data {
      id
      grid_state
      created_at
      updated_at
      layout_version
    }
  }
}`;

const DELETE_ESTIMATE_GRID_LAYOUT = gql`
  mutation deleteEstimateGridLayout($layout_version: String!, $project_id: String!, $org_id: String!) {
    deleteEstimateGrid(layout_version: $layout_version, project_id: $project_id, org_id: $org_id) {
      message
    }
  }
`;

export default {
    SAVE_GRID_LAYOUT,
    GET_GRID_LAYOUT,
    RESET_GRID_LAYOUT,
    GET_GRID_ESTIMATE_LAYOUT,
    SAVE_ESTIMATE_GRID_LAYOUT,
    DELETE_ESTIMATE_GRID_LAYOUT,
    RESET_ESTIMATE_GRID_LAYOUT,
};

import React, {
    Fragment, useState, useEffect, useContext,
    useRef,
} from 'react';
import useStyles from './Resources.style';
import ResourcesHeader from '../../components/ResourcesHeader';
import { Box, Button } from '@mui/material';
import resourcesQueries from '../../../../queries/resources';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import Loader from '../../../../components/Loader';
import { CellRendererType } from '../../../../types/AgGridTypes';
import Pagination from '../../../../components/paginationTable';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import { QueryErrorResponse, OutletContext } from '../../../../types/CommoditiesListType';
import { useLocation, useOutletContext } from 'react-router-dom';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import { formatCurr, useDisplayCurrFormatter } from '../../../../hooks/useFormatCurrency';
import { CURR_FORMAT } from '../../../../helper/CountryFlag';
import AgGridComponent from '../../../../components/agGridTableComponent/index';
import { dateFormat } from '../../../../helper/DateFormat';
import { AgGridReact } from 'ag-grid-react';
import IndustriesType from '../../../../types/IndustriesType';

interface LocationState {
    unitCostId?: string;
    code: string;
    description: string;
    resourceType: string;
}

function Resources() {
    const classes = useStyles();
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const projectId = projectCtx?.project?.id;
    const formatCurrency = useDisplayCurrFormatter();
    const [searchText, setSearchText] = useState('');
    const [hideUnusedFlag, setHideUnusedFlag] = useState(true);
    const gridRef = useRef<AgGridReact<IndustriesType>>(null);
    const location = useLocation();
    const {
        code, description, resourceType,
    } = (location.state || {}) as LocationState;
    const [unitCostId, setUnitCostId] = useState<string | undefined>(
        (location.state as LocationState)?.unitCostId,
    );
    const [resourceFilterButtonState, setResourceFilterButtonState] = useState(resourceType ? `type: "${resourceType}"` : 'type: "L"');
    const buttonsArr = [
        // { id: 1, name: 'All', value: '' },
        { id: 1, name: 'Labour', value: 'type: "L"' },
        { id: 2, name: 'Construction Equipment', value: 'type: "E"' },
        { id: 3, name: 'Permanent Equipment', value: 'type: "P"' },
        { id: 4, name: 'Materials', value: 'type: "M"' },
        { id: 5, name: 'Sub-Contract', value: 'type: "S"' },
        { id: 6, name: 'Other', value: 'type: "Z"' },
    ];

    const local:string = CURR_FORMAT[projectCtx?.project?.currency || 'USD'];

    const customCellRenderer = (params: CellRendererType) => {
        const colName = params.colDef.field;
        if (params?.value != null) {
            switch (colName) {
            case 'cost_per_unit':
                return params.value || params.value === 0 ? `${formatCurrency((+params.value).toFixed(2))}` : '-';
            case 'total_cost':
                return params.value || params.value === 0 ? `${formatCurrency((+params.value).toFixed(0))}` : '-';
            case 'total_quantity':
            case 'cost_input':
                return params.value || params.value === 0 ? `${formatCurr((+params.value).toFixed(2), local)}` : '-';
            case 'escalation_factor':
                return params?.value?.toString() ? `${params.value}%` : '-';
            case 'date':
                return params.value;
            default:
                return params?.value;
            }
        }
        if (params?.value === null) return '-';
        return '';
    };

    const columnDefs = [
        {
            field: 'code',
            type: 'string',
            initialWidth: 100,
            headerName: 'Code',
        },
        {
            field: 'description', initialWidth: 300, headerName: 'Description',
        },
        {
            field: 'unit', initialWidth: 150, headerName: 'Unit',
        },
        {
            field: 'cost_input',
            initialWidth: 190,
            headerName: 'Input Rate',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'currency', initialWidth: 150, headerName: 'Currency',
        },
        {
            field: 'date', initialWidth: 130, headerName: 'Date', cellRenderer: customCellRenderer, enableValue: false,
        },
        {
            field: 'escalation_factor',
            initialWidth: 150,
            headerName: 'Escalation',
            cellRenderer: customCellRenderer,
        },
        {
            field: 'cost_per_unit', initialWidth: 150, headerName: 'Rate', cellRenderer: customCellRenderer, type: 'numericColumn',
        },
        {
            field: 'unit_rate',
            initialWidth: 150,
            headerName: 'Unit Rate',
        },
        {
            field: 'total_quantity',
            initialWidth: 150,
            headerName: 'Total Qty',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
        {
            field: 'total_cost',
            initialWidth: 150,
            headerName: 'Total Cost',
            cellRenderer: customCellRenderer,
            type: 'numericColumn',
        },
    ];
    const cont: OutletContext = useOutletContext();

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const { data, isFetching, refetch } = useGQLQuery(
        `GetResourceInfo-${projectId || 0}`,
        resourcesQueries.GET_RESOURCES_INFO(
            resourceFilterButtonState,
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
            unitCostId || '',
            false,
            hideUnusedFlag,
        ),
        {},
        {
            onError: handleApiError,
        },
    );

    const resourcesData = data?.resource;
    const tableData = resourcesData?.data;
    const pageInfo = resourcesData?.pageInfo;

    const filterHandler = (param: string) => {
        setResourceFilterButtonState(param);
        setPageSkipValue(0);
        setHideUnusedFlag(true);
    };

    useEffect(() => {
        refetch();
    }, [resourceFilterButtonState, pageSkipValue, hideUnusedFlag, unitCostId]);

    const handlePageChange = (page: number) => {
        // Fetch new page data
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    const rowData = tableData && tableData.length ? tableData.map((item) => ({
        ...item,
        date: item.date ? dateFormat(+item.date, 'dd/mm/yyyy') : item.date,
    })) : [];
    const totalPages = (pageInfo && pageInfo.totalcount) ? Math.ceil(pageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;
    const handleClear = () => {
        window.history.replaceState({}, '');
        setUnitCostId('');
    };

    const rowIndex = rowData.findIndex((item) => item.code === code);

    useEffect(() => {
        if (gridRef.current && rowIndex >= 0 && unitCostId) {
            setTimeout(() => {
                gridRef?.current?.api?.ensureIndexVisible(rowIndex, 'top');
                const rowNode = gridRef?.current?.api?.getDisplayedRowAtIndex(rowIndex);
                if (rowNode) {
                    gridRef?.current?.api?.getSelectedNodes().forEach((node) => {
                        node.setSelected(false);
                    });
                    rowNode.setSelected(true);
                }
            }, 100);
        }
    }, [rowIndex, rowData]);

    return (
        <Fragment>
            <ResourcesHeader
                unitCostId={unitCostId}
                setSearchText={setSearchText}
                searchText={searchText}
                hideUnusedFlag={hideUnusedFlag}
                setHideUnusedFlag={setHideUnusedFlag}
                isResourceFetching={isFetching}
            />
            <Box>
                <Box className={classes.resourceBody}>
                    <Box className={classes.selectionSec}>
                        {buttonsArr.map((item) => (
                            <Box key={item.id} className={item.value === resourceFilterButtonState ? classes.newButton : classes.outlineButton}>
                                <Button
                                    onClick={() => filterHandler(item.value)}
                                    disabled={isFetching || (!!unitCostId && item.value !== resourceFilterButtonState)}
                                >
                                    {item.name}
                                </Button>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box className={unitCostId ? classes.tableSec : classes.tableSec2}>
                    { unitCostId && (
                        <Box className={classes.filterDesc}>
                            <span>Data filtered by: </span>
                            <span>
                                {code}
                                {' '}
                                -
                                {' '}
                                {description}
                            </span>
                            <button type="button" onClick={() => handleClear()}>Clear</button>
                        </Box>
                    )}
                    <AgGridComponent
                        columnDefs={columnDefs}
                        gridRef={gridRef}
                        rowData={rowData}
                        changeSortingValue={() => {}}
                        isExportEnabled
                        isClipboardEnabled
                        isRangeSelectable
                        isToolPanelsEnabled
                        isStatusBarEnabled
                        moduleName="Resources"
                        colFormat={['cost_per_unit', 'total_cost']}
                        quickFilterText={searchText}
                        setSearchText={setSearchText}
                    />
                    <Loader loading={isFetching} />
                </Box>
            </Box>
        </Fragment>
    );
}

export default Resources;
